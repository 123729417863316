<template>
    <section>
      <div id="mainModal" uk-modal esc-close="false" bg-close="false">
        <div class="uk-modal-dialog">
          <button
            class="uk-modal-close-default"
            type="button"
            uk-close
            @click="hideModal(false)"
          ></button>
          <div class="uk-modal-header">
            <h2 class="uk-modal-title">Reject Request</h2>
          </div>
          <div class="uk-modal-body">
            <p>Are you sure to reject Early Wage Access request from <span class="uk-text-bold">{{ data.fullname }} ({{ data.position }} at {{ data.company_name }})</span> ?</p>
            <div class="uk-margin">
                <label class="uk-form-label">Rejection Notes</label>
                <textarea
                    class="uk-textarea"
                    rows="5"
                    v-model="form.notes"
                    placeholder="Notes"
                    name="notes"
                    v-validate="'required'"
                ></textarea>
                <span
                    class="uk-text-small uk-text-danger"
                    v-show="errors.has('notes')"
                    >{{ errors.first("notes") }}</span
                >
            </div>
          </div>
          <div class="uk-modal-footer uk-text-right">
            <section v-if="isLoading">
              <button class="uk-button uk-button-primary" type="button" disabled>
                <div uk-spinner></div>
              </button>
            </section>
            <section v-else>
              <button
                class="uk-button uk-button-danger uk-border-rounded"
                type="button"
                @click="save"
              >
                Reject
              </button>
            </section>
          </div>
        </div>
      </div>
    </section>
</template>
  
<script>
import { PREFIX_IMAGE } from "@/utils/constant";

export default {
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            images: PREFIX_IMAGE,
            isLoading: false,
            form: {
                _id: this.data._id,
                notes: null
            }
        };
    },
    async mounted() {
        window.UIkit.modal("#mainModal").show();
    },
    methods: {
        hideModal(isRefresh) {
            window.UIkit.modal("#mainModal").$destroy(true);
            this.$emit("hideModal", isRefresh);
        },
        async save() {
            const validate = await this.$validator.validateAll();
            if (!validate || this.$validator.errors.any()) {
                this.isLoading = false;
                return;
            }
            window.UIkit.modal("#mainModal").$destroy(true);
            this.$emit("save", this.form);
        }
    },
};
</script>
