<template>
    <section>
      <div id="mainModal" uk-modal esc-close="false" bg-close="false">
        <div class="uk-modal-dialog">
          <button
            class="uk-modal-close-default"
            type="button"
            uk-close
            @click="hideModal(false)"
          ></button>
          <div class="uk-modal-header">
            <h2 class="uk-modal-title">Mitra Details</h2>
          </div>
          <div class="uk-modal-body">
            <div class="uk-margin">
              <span class="uk-text-large uk-text-bold">{{ data.fullname || '-' }}</span><br>
              <span><img :src="`${images}/ic_person-clipboard.svg`" alt="person icon"></span><span class="uk-text-small"> {{ data.employee_id || '-' }}</span><br>
              <span><img :src="`${images}/ic_round-local-phone.svg`" alt="phone icon"></span><span class="uk-text-small"> {{ data.phone_number || '-' }} </span>
              <span class="uk-margin-small-left"><img :src="`${images}/ic_baseline-email.svg`" alt="envelope icon"></span><span class="uk-text-small">{{ data.email || '-' }}</span>
            </div>
            <div class="uk-margin">
              <span class="uk-text-bold">Personal Details</span><br>
              <div class="uk-flex uk-flex-between uk-margin-small-top">
                <span class="uk-text-small">NIK KTP</span>
                <span class="uk-text-small">{{ data.identity_number || '-' }}</span>
              </div>
              <div class="uk-flex uk-flex-between uk-margin-small-top">
                <span class="uk-text-small">Tanggal Lahir</span>
                <span class="uk-text-small">{{ moment(data.birthdate).format('DD MMM YYYY') || '-' }}</span>
              </div>
            </div>
            <hr>
            <div class="uk-margin">
              <span class="uk-text-bold">Bank Details</span><br>
              <div class="uk-flex uk-flex-between uk-margin-small-top">
                <span class="uk-text-small">Nomor Rekening</span>
                <span class="uk-text-small">{{ data.bank_account_number || '-' }}</span>
              </div>
              <div class="uk-flex uk-flex-between uk-margin-small-top">
                <span class="uk-text-small">Nama Bank</span>
                <span class="uk-text-small">{{ data.bank_name || '-' }}</span>
              </div>
              <div class="uk-flex uk-flex-between uk-margin-small-top">
                <span class="uk-text-small">Penerima Manfaat<br>(nama pemilik bank)</span>
                <span class="uk-text-small">{{ data.bank_account_name || '-' }}</span>
              </div>
            </div>
            <hr>
            <div class="uk-margin">
              <span class="uk-text-bold">Employment Details</span><br>
              <div class="uk-flex uk-flex-between uk-margin-small-top">
                <span class="uk-text-small">Tanggal Bergabung</span>
                <span class="uk-text-small">{{ moment(data.join_date).format('DD MMM YYYY') || '-' }}</span>
              </div>
              <div class="uk-flex uk-flex-between uk-margin-small-top">
                <span class="uk-text-small">Posisi Bekerja</span>
                <span class="uk-text-small">{{ data.position || '-' }}</span>
              </div>
              <div class="uk-flex uk-flex-between uk-margin-small-top">
                <span class="uk-text-small">Area</span>
                <span class="uk-text-small">{{ data.area || '-' }}</span>
              </div>
              <div class="uk-flex uk-flex-between uk-margin-small-top">
                <span class="uk-text-small">Perusahaan Tempat Bekerja</span>
                <span class="uk-text-small">{{ data.company_name || '-' }}</span>
              </div>
            </div>
            <hr>
            <div class="uk-margin">
              <div class="uk-flex uk-flex-between">
                <span class="uk-text-bold">EWA Vendor</span>
                <span class="uk-text-small">{{ data.ewa_vendor_id ? data.ewa_vendor_id.name : '-' }}</span>
              </div>
            </div>
          </div>
          <div class="uk-modal-footer uk-flex" :class="status != 1 ? 'uk-flex-right' : 'uk-flex-between'">
            <div v-if="status == 1">
              <button
                class="uk-button uk-button-success uk-border-rounded"
                type="button"
                @click="showApprove"
              >
                Approve
              </button>
              <button
                class="uk-button uk-button-danger uk-border-rounded uk-margin-small-left"
                type="button"
                @click="showReject"
              >
                Reject
              </button>
            </div>
            <div>
              <button
                class="uk-button uk-button-primary uk-border-rounded"
                type="button"
                @click="hideModal(false)"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>
  
<script>
import { PREFIX_IMAGE } from "@/utils/constant";
import moment from 'moment';

export default {
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            moment: moment,
            images: PREFIX_IMAGE,
            isLoading: false,
            status: this.data.status
        };
    },
    async mounted() {
        window.UIkit.modal("#mainModal").show();
    },
    methods: {
        hideModal(isRefresh) {
            window.UIkit.modal("#mainModal").$destroy(true);
            this.$emit("hideModal", isRefresh);
        },
        showApprove() {
            window.UIkit.modal("#mainModal").$destroy(true);
            this.$emit("showApprove", this.data);
        },
        showReject() {
            window.UIkit.modal("#mainModal").$destroy(true);
            this.$emit("showReject", this.data);
        }
    },
};
</script>

<style>
body {
  color: black;
}
</style>
