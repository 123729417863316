<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-flex uk-flex-between uk-margin">
            <div class="uk-flex uk-flex-left uk-vertical-align-middle">
                <div class="uk-inline">
                    <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search"></span>
                    <input class="uk-input" @keyup.enter="(e) => changeMeta(e, 'fullname')" type="text" placeholder="Search Mitra Name">
                </div>
                <div class="uk-margin-left">
                    <div class="uk-form-controls uk-width-small">
                        <select class="uk-select" id="form-horizontal-select" @change="(e) => changeMeta(e, 'status')">
                            <option :value="null">Select Status</option>
                            <template v-for="(item, x) in status_options" >
                                <option :value="item.value" :key="x">{{item.label}}</option>
                            </template>
                        </select>
                    </div>
                </div>
            </div>
            <div v-if="consist(user_cred.roles, ['admin-community', 'qwe123'])" class="uk-flex uk-flex-right">
                <button class="uk-button uk-button-primary uk-border-rounded" type="button" @click="$router.push('/admin/community/ewa/vendor')">
                    <img :src="`${images}/ic_bank-building-white.svg`" alt="bank icon">
                    <span class="uk-margin-small-left">EWA Vendor List</span>
                </button>
            </div>
        </div>
        <div class="uk-card uk-card-default">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-medium uk-table-striped">
                    <thead>
                        <tr class="uk-text-right">
                            <th class="uk-text-center">Request ID</th>
                            <th class="uk-text-center uk-text-nowrap">Request Date</th>
                            <th class="uk-text-center">Full Name</th>
                            <th class="uk-text-center">Company</th>
                            <th class="uk-text-center">Office</th>
                            <th class="uk-text-center">Role</th>
                            <th class="uk-text-center">Salary</th>
                            <th class="uk-text-center uk-text-nowrap">Status</th>
                            <th class="uk-text-center uk-text-nowrap">EWA Vendor</th>
                            <th class="uk-width-auto uk-text-center">Action</th>
                        </tr>
                    </thead>
                    <loading-table v-if="isLoading" :colspan="10"/>
                    <tbody v-else-if="page_data.docs.length>0">
                        <tr v-for="(data, i) in page_data.docs" :key="i" class="uk-text-left">
                            <td class="uk-text-center">{{preview(data._id)}}</td>
                            <td class="uk-text-center uk-text-nowrap">{{preview(moment(data.created_at).format('DD MMM YYYY'))}}</td>
                            <td class="uk-text-center">{{preview(data.fullname)}}</td>
                            <td class="uk-text-center">{{preview(data.company_name)}}</td>
                            <td class="uk-text-center">{{preview(data.office_name)}}</td>
                            <td class="uk-text-center">{{preview(data.position)}}</td>
                            <td class="uk-text-center uk-text-nowrap">{{previewCurrency(data.salary)}}</td>
                            <td class="uk-text-center uk-text-nowrap uk-text-bold" :style="statusStyle(data.status)">{{previewStatus(data.status)}}</td>
                            <td class="uk-text-center uk-text-nowrap">{{preview(data.ewa_vendor_id? data.ewa_vendor_id.name : null)}}</td>
                            <td class="uk-text-center">
                                <button class="uk-button uk-button-small uk-button-default" type="button">Actions</button>
                                <div id="actionDropdown" uk-dropdown="mode: click">
                                    <ul class="uk-nav uk-dropdown-nav uk-text-left">
                                        <li><a @click="showDetail(data)" class="uk-dropdown-close">Details</a></li>
                                        <template v-if="data.status == 1">
                                            <li class="uk-nav-divider"></li>
                                            <li><a @click="showApprove(data)" style="color: #5DC93B;" class="uk-dropdown-close">Approve</a></li>
                                            <li class="uk-nav-divider"></li>
                                            <li><a @click="showReject(data)" style="color: #F44336;" class="uk-dropdown-close">Reject</a></li>
                                            <li class="uk-nav-divider"></li>
                                        </template>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="10"/>
                </table>
            </div>
            <pagination
                :total-data="page_data.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>
        <RejectModal v-if="showRejectModal" :data="component_data" @hideModal="hideModal" @save="doReject"/>
        <ApproveModal v-if="showApproveModal" :data="component_data" @hideModal="hideModal" @save="doApprove"/>
        <DetailModal v-if="showDetailModal" :data="component_data" @hideModal="hideModal" @showApprove="showApprove" @showReject="showReject"/>
    </div>
</template>

<script>
import formatter from "@/utils/formatter";
import { mapActions } from 'vuex';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import LoadingTable from '@/components/globals/tables/Loading';
import Pagination from '@/components/globals/Pagination';
import { PREFIX_IMAGE } from '@/utils/constant';
import moment from 'moment';
moment.locale('id');
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';
import { getUserLogin } from '@/utils/auth';
import RejectModal from './reject';
import ApproveModal from './approve';
import DetailModal from './detail';

export default {
    data() {
        return {
            user_cred: getUserLogin(),
            moment: moment,
            images: PREFIX_IMAGE,
            meta: {
                limit: 10,
                page: 1
            },
            page_data: {docs: [], totalDocs: 0},
            isLoading: true,
            showRejectModal: false,
            showApproveModal: false,
            showDetailModal: false,
            component_data: null,
            status_options: [
                {value: 1, label: 'New Request'},
                {value: 2, label: 'Approved'},
                {value: 3, label: 'Rejected'},
            ]
        };
    },
    components: {
        EmptyTable,
        Pagination,
        LoadingTable,
        RejectModal,
        ApproveModal,
        DetailModal
    },
    watch: {
        meta() {
            this.getInitPageData();
        },
    },
    beforeMount() {
        if(window.UIkit.modal('.deleteModal')) window.UIkit.modal('.deleteModal').$destroy(true);
        if(window.UIkit.modal('.mainModal')) window.UIkit.modal('.mainModal').$destroy(true);
        if(window.UIkit.modal('.generateModal')) window.UIkit.modal('.generateModal').$destroy(true);
    },
    async mounted() {
        await this.getInitPageData();
    },
    methods: {
        ...mapActions({
            getEWA: 'community/getEWA',
            rejectEWA: 'community/rejectEWA',
            acceptEWA: 'community/acceptEWA'
        }),
        async getInitPageData(){
            this.isLoading = true;
            this.page_data = await this.getEWA(this.meta);
            this.isLoading = false;
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        changeMeta(e, prop) {
            this.meta = {
                ...this.meta,
                [prop]: e.target.value
            };
        },
        preview(data) {
            if (data == null) {
                return '-';
            }
            if (data.length > 26) {
                return data.substring(0,26)+'...';
            } else {
                return data;
            }
        },
        previewCurrency(data) {
            if (data == null) {
                return '-';
            } else {
                return `Rp${formatter.thousandSeparator(data)}`;
            }
        },
        previewStatus(data) {
            if (data == 1) {
                return 'New Request';
            } else if (data == 2) {
                return 'Approved';
            } else if (data == 3) {
                return 'Rejected';
            } else {
                return '-';
            }
        },
        statusStyle(data) {
            if (data == 1) {
                return 'color: #303D4E;';
            } else if (data == 2) {
                return 'color: #5DC93B;';
            } else if (data == 3) {
                return 'color: #F44336;';
            } else {
                return '-';
            }
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
        hideModal(isRefresh){
            if (isRefresh) this.getInitPageData();
            
            this.showApproveModal = false;
            this.showRejectModal = false;
            this.showDetailModal = false;
            this.component_data = null;
        },
        showReject(data) {
            window.UIkit.dropdown('#actionDropdown').hide(0);
            this.showDetailModal = false;
            this.component_data = data;
            this.showRejectModal = true;
        },
        async doReject(data) {
            try {
                this.isLoading = true;
                const response = await this.rejectEWA(data);
                this.isLoading = false;

                if (response) {
                    notificationSuccess('Data Deleted!');
                    this.getInitPageData();
                } else {
                    notificationDanger('Failed to delete!');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
        showApprove(data) {
            window.UIkit.dropdown('#actionDropdown').hide(0);
            this.showDetailModal = false;
            this.component_data = data;
            this.showApproveModal = true;
        },
        async doApprove(data) {
            try {
                this.isLoading = true;
                const response = await this.acceptEWA(data);
                this.isLoading = false;

                if (response) {
                    notificationSuccess('Data Approved!');
                    this.getInitPageData();
                } else {
                    notificationDanger('Failed to approve!');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
        showDetail(data) {
            window.UIkit.dropdown('#actionDropdown').hide(0);
            this.component_data = data;
            this.showDetailModal = true;
        },
    },
};
</script>
