<template>
    <section>
      <div id="mainModal" uk-modal esc-close="false" bg-close="false">
        <div class="uk-modal-dialog">
          <button
            class="uk-modal-close-default"
            type="button"
            uk-close
            @click="hideModal(false)"
          ></button>
          <div class="uk-modal-header">
            <h2 class="uk-modal-title">Approve Request</h2>
          </div>
          <div class="uk-modal-body">
            <p>Approve Early Wage Access request from <span class="uk-text-bold">{{ data.fullname }} ({{ data.position }} at {{ data.company_name }})</span> ?</p>
            <div class="uk-margin">
                <label class="uk-form-label">Select EWA Vendor</label>
                <multiselect
                  v-model="form.ewa_vendor_id"
                  placeholder="Select EWA Vendor"
                  label="name"
                  name="vendor"
                  track-by="_id"
                  v-validate="'required'"
                  :options="options">
                </multiselect>
                <span class="uk-text-small uk-text-danger" v-show="errors.has('vendor')">{{ errors.first('vendor') }}</span>
            </div>
            <div class="uk-margin">
                <label class="uk-form-label">Approval Notes</label>
                <textarea
                    class="uk-textarea"
                    rows="5"
                    v-model="form.notes"
                    placeholder="Notes"
                    name="notes"
                    v-validate="'required'"
                ></textarea>
                <span
                    class="uk-text-small uk-text-danger"
                    v-show="errors.has('notes')"
                    >{{ errors.first("notes") }}</span
                >
            </div>
          </div>
          <div class="uk-modal-footer uk-text-right">
            <section v-if="isLoading">
              <button class="uk-button uk-button-primary" type="button" disabled>
                <div uk-spinner></div>
              </button>
            </section>
            <section v-else>
              <button
                class="uk-button uk-button-primary uk-border-rounded"
                type="button"
                @click="save"
              >
                Approve
              </button>
            </section>
          </div>
        </div>
      </div>
    </section>
</template>
  
<script>
import { PREFIX_IMAGE } from "@/utils/constant";
import { mapActions } from 'vuex';

export default {
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            images: PREFIX_IMAGE,
            isLoading: false,
            form: {
                _id: this.data._id,
                ewa_vendor_id: null,
                notes: null
            },
            options: []
        };
    },
    async mounted() {
        window.UIkit.modal("#mainModal").show();
        this.options = await this.getEWAVendor();
    },
    methods: {
        ...mapActions({
            getEWAVendor: 'community/getEWAVendor'
        }),
        hideModal(isRefresh) {
            window.UIkit.modal("#mainModal").$destroy(true);
            this.$emit("hideModal", isRefresh);
        },
        async save() {
            const validate = await this.$validator.validateAll();
            if (!validate || this.$validator.errors.any()) {
                this.isLoading = false;
                return;
            }
            window.UIkit.modal("#mainModal").$destroy(true);
            this.$emit("save", this.form);
        }
    },
};
</script>
